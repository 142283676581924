const baseColors = {
  // neutral colors
  black: '#1e282f',
  gray_00: '#333f48',
  gray_01: '#42515b',
  gray_02: '#667880',
  gray_03: '#8f9fa1',
  gray_04: '#c9d1d0',
  gray_05: '#e8ebec',
  gray_06: '#f2f5f6',
  white: '#ffffff',

  // brand colors
  petrol_00: '#003239',
  petrol_01: '#004851',
  petrol_02: '#2d7b88',
  petrol_03: '#baddda',
  petrol_04: '#eefcf9',
  pink_00: '#731934',
  pink_01: '#b23d59',
  pink_02: '#d0858b',
  pink_03: '#f2d4d7',
  pink_04: '#ffeef0',

  // Semantic colors
  red_00: '#760505',
  red_01: '#b22f2f',
  red_02: '#d05656',
  red_03: '#ffbdbd',
  red_04: '#fee4e0',
  yellow_00: '#766442',
  yellow_01: '#90640e',
  yellow_02: '#f0bb00',
  yellow_03: '#ffd877',
  yellow_04: '#fff0bc',
  green_00: '#175341',
  green_01: '#32765e',
  green_02: '#4ba676',
  green_03: '#9bd2b5',
  green_04: '#ccf3de',

  // Extended colors
  pumpkin_00: '#973700',
  pumpkin_01: '#bc5b01',
  pumpkin_02: '#ed9d40',
  pumpkin_03: '#fbc381',
  pumpkin_04: '#f7ddbd',
  lime_00: '#577036',
  lime_01: '#538216',
  lime_02: '#7daf3e',
  lime_03: '#a8d470',
  lime_04: '#dceec6',
  blue_00: '#0e5b7b',
  blue_01: '#09779a',
  blue_02: '#3fa8c6',
  blue_03: '#8ed4e8',
  blue_04: '#c9e5ed',
  night_00: '#2b365b',
  night_01: '#344582',
  night_02: '#5773d9',
  night_03: '#8da6ff',
  night_04: '#cbd6ff',
  viole_00: '#421d66',
  viole_01: '#683c95',
  viole_02: '#8f5dc1',
  viole_03: '#c08df2',
  viole_04: '#d2bee6',
  flamingo_00: '#831557',
  flamingo_01: '#a14d7f',
  flamingo_02: '#dc7eb6',
  flamingo_03: '#f59fd3',
  flamingo_04: '#f2d3e6',
};

const colors = {
  ...baseColors,

  primary_00: baseColors.petrol_00,
  primary_01: baseColors.petrol_01,
  primary_02: baseColors.petrol_02,
  primary_03: baseColors.petrol_03,
  primary_04: baseColors.petrol_04,

  neutral_00: baseColors.gray_00,
  neutral_01: baseColors.gray_01,
  neutral_02: baseColors.gray_02,
  neutral_03: baseColors.gray_03,
  neutral_04: baseColors.gray_04,
  neutral_05: baseColors.gray_05,
  neutral_06: baseColors.gray_06,

  danger_00: baseColors.red_00,
  danger_01: baseColors.red_01,
  danger_02: baseColors.red_02,
  danger_03: baseColors.red_03,
  danger_04: baseColors.red_04,

  warning_00: baseColors.yellow_00,
  warning_01: baseColors.yellow_01,
  warning_02: baseColors.yellow_02,
  warning_03: baseColors.yellow_03,
  warning_04: baseColors.yellow_04,

  success_00: baseColors.green_00,
  success_01: baseColors.green_01,
  success_02: baseColors.green_02,
  success_03: baseColors.green_03,
  success_04: baseColors.green_04,

  info_00: baseColors.blue_00,
  info_01: baseColors.blue_01,
  info_02: baseColors.blue_02,
  info_03: baseColors.blue_03,
  info_04: baseColors.blue_04,

  focus: '#57a0ee',
  border: baseColors.gray_04,
};

module.exports = {
  colors,
};
