import { HOSTNAME } from '@shared/constants';
import { createFeatureToggle } from '@shared/createFeatureToggle';

export const auditLogsFeatureToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const agreememtTemplateWorkingHoursUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const agreememtTemplateWorkingTimeRulesUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

// cleanup related code when we decide to stop the support of the old OHs
export const oldOptimalHeadcountsToggle = true;

export const bankHolidaysSalesAndSalaryAffectingUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const flpOnlyAccountFeatureToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const flpPageFeatureToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const scheduleDragDropDebuggingToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const newDatePickerFeatureToggle = createFeatureToggle({
  showOnEnvironment: 'development',
});

export const analythicsScriptsToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

// This disables optimization of rendering the print component only on print
export const printScheduleDevToggle = createFeatureToggle({
  showOnEnvironment: 'development',
});

export const reactRouterDevToggle = createFeatureToggle({
  showOnHostnames: [], // disabled until after CF!
});

export const eSigningEditIconFeatureToggle = createFeatureToggle({
  showOnHostnames: [],
});
