import React, { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import LegacyDiv from '@shared/components/LegacyDiv';

import Modal from '..';
import Button, { ButtonProps } from '../../legacy/Button';

interface ConfirmModalProps extends Partial<ReactModal.Props> {
  handlePrimaryAction: ButtonProps['onClick'];
  handleSecondaryAction: ReactModal.Props['onRequestClose'];
  primaryActionText: ButtonProps['label'];
  secondaryActionText: ReactNode;
  useSecondaryButton?: ButtonProps['secondary'];
  testIdPrimaryAction?: ButtonProps['data-test-id'];
  testIdSecondaryAction?: ButtonProps['data-test-id'];
  open: ReactModal.Props['isOpen'];
  title: ReactNode;
  disabled?: ButtonProps['disabled'];
  disabledPrimary?: ButtonProps['disabled'];
  isLoading?: boolean;
  testId?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  useSecondaryButton,
  testIdPrimaryAction = 'testIdPrimaryAction',
  testIdSecondaryAction = 'testIdSecondaryAction',
  open,
  title,
  children,
  disabled,
  disabledPrimary,
  isLoading,
  testId,
  ...modalProps
}) => {
  return (
    <Modal
      testId={testId}
      isOpen={open}
      onRequestClose={handleSecondaryAction}
      className="confirmModal"
      {...modalProps}>
      <h4 className="text-capitalize-first-letter break-word">{title}</h4>
      <LegacyDiv className="padding-2-top padding-4-bottom">
        {children}
      </LegacyDiv>
      <LegacyDiv className="flex-row flex-justify-center">
        <Button
          primary
          className="margin-4-right text-capitalize-first-letter"
          data-test-id={testIdPrimaryAction}
          onClick={handlePrimaryAction}
          label={primaryActionText}
          disabled={disabled || disabledPrimary || isLoading}
          inProgress={isLoading}
        />
        <Button
          secondary={useSecondaryButton}
          negative
          className="text-capitalize-first-letter"
          data-test-id={testIdSecondaryAction}
          onClick={handleSecondaryAction}
          disabled={disabled || isLoading}>
          {secondaryActionText}
        </Button>
      </LegacyDiv>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  open: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};

export default ConfirmModal;
