import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';

import { transformApi, transformApiDefaultState } from '../../../api/utils';
import {
  ApiError,
  ApiOptions,
  ApiState,
  InitialApiState,
  SuperAgentApi,
  WrappedApi,
} from './types';

const useWrapApi = () => {
  const mounted = useRef(false);
  const apiAborts = useRef<{ (): void }[]>([]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      apiAborts.current.forEach((abort: () => void) => abort());
    };
  }, []);

  return <Params extends unknown[], DataType>(
    api: ApiOptions | SuperAgentApi<Params, DataType>,
    setState: any,
  ) => {
    const transformedApi = transformApi(api);
    return (...params: any) => {
      setState((state: any) => ({
        ...state,
        ...transformApiDefaultState,
        isAttempted: true,
        isLoading: true,
      }));

      const [calledApi, abort] = transformedApi(...params);
      if (abort) apiAborts.current.push(abort);

      return calledApi
        .then((result: any) => {
          if (mounted.current) {
            setState((state: any) => ({ ...state, ...result }));
            return result;
          }
        })
        .catch((error: any) => {
          if (mounted.current) {
            setState((state: any) => ({ ...state, ...error }));
          }

          throw error;
        });
    };
  };
};

const initialState = <DataType>({
  initialData,
}: { initialData?: DataType } = {}): InitialApiState<DataType> => ({
  ...transformApiDefaultState,
  data: initialData,
  invalidated: Date.now(),
});

const getOptions = <Params extends unknown[], DataType>(
  arg1: ApiOptions | SuperAgentApi<Params, DataType>,
): ApiOptions | undefined =>
  typeof arg1 === 'object' ? arg1 || undefined : undefined;

const useApiState = <DataType>(
  startState: () => InitialApiState<DataType>,
): [ApiState<DataType>, Dispatch<InitialApiState<DataType>>] => {
  const [state, setState] = useState<InitialApiState<DataType>>(startState);

  // modifiers put to separate useMemo to achieve reference equality on state change
  const modifiers = useMemo(
    () => ({
      setData: (data: DataType) =>
        setState((prevState) => ({ ...prevState, data })),
      setErrors: (errors: ApiError) =>
        setState((prevState) => ({
          ...prevState,
          errors,
        })),
      reset: () =>
        setState({
          ...initialState(),
          invalidated: Date.now(),
        }),
      invalidate: () =>
        setState((prevState) => ({
          ...initialState(),
          data: prevState.data,
          invalidated: Date.now(),
        })),
    }),
    [],
  );

  const stateAndModifiers = useMemo(
    () => ({
      ...state,
      ...modifiers,
    }),
    [state, modifiers],
  );

  return [stateAndModifiers, setState];
};

// The first argument should either be options or an API function
//  You can pass in multiple APIs if you want them to share state
export const useApi = <DataType, Params extends unknown[]>(
  arg1: ApiOptions | SuperAgentApi<Params, DataType>,
  ...apis: SuperAgentApi<Params, DataType>[]
) => {
  const options = useRef<ApiOptions | undefined>(
    getOptions<Params, DataType>(arg1),
  );

  const [state, setState] = useApiState<DataType>(() =>
    initialState<DataType>(options.current),
  );
  const wrapApi = useWrapApi();
  const wrappedApis = useRef<null | WrappedApi<DataType, Params>[]>(null);
  if (wrappedApis.current === null) {
    const apisToWrap = options.current ? apis : [arg1, ...apis];
    wrappedApis.current = apisToWrap.map((api) =>
      wrapApi<Params, DataType>(api, setState),
    );
  }

  return [
    state,
    ...(wrappedApis.current as [
      WrappedApi<DataType, Params>,
      ...WrappedApi<DataType, Params>[],
    ]),
  ] as const;
};

type UseFetchParams<DataType, Args extends unknown[]> = Args extends never[]
  ? [
      SuperAgentApi<Args, DataType> | null | false,
      (undefined | unknown[])?,
      any[]?,
    ]
  : [SuperAgentApi<Args, DataType> | null | false, Args, any[]?];

export const useFetch = <DataType, Args extends unknown[]>(
  ...[api, params = [], dependencies]: UseFetchParams<DataType, Args>
) => {
  const [state, setState] = useApiState<DataType>(() =>
    initialState<DataType>(),
  );

  const wrapApi = useWrapApi();

  useEffect(() => {
    if (!api) return;

    wrapApi(api, setState)(...params).catch((e: any) => e);
  }, [...(dependencies || params), state.invalidated]);

  return state;
};
