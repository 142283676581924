import React, { ForwardedRef, forwardRef } from 'react';

import classNames from 'classnames';
import t from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import useTranslate from '../../hooks/useTranslate';
import { withForm } from '../Form';
import Label from '../Label';

import s from './index.module.scss';

interface TextAreaProps {
  label?: string;
  info?: string;
  error?: string;
  placeholder?: string;
  required?: boolean | string;
  resize?: boolean;
  id?: string;
  className?: never;
  rows?: number;
  disabled?: boolean;
  readOnly?: boolean;
}

export const TextArea = forwardRef(
  (
    {
      label = '',
      info,
      error,
      placeholder,
      required,
      id = crypto.randomUUID(),
      className: _className,
      resize = false,
      ...props
    }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const translate = useTranslate();

    return (
      <LegacyDiv className={s.textareaWrap}>
        {label && <Label htmlFor={id} label={label} required={required} />}

        <textarea
          {...props}
          ref={ref}
          id={id}
          placeholder={placeholder ? translate(placeholder) : ''}
          className={classNames(s.textarea, resize && s.resize)}
        />

        {error && <span className={s.errorMsg}>{translate(error)}</span>}

        {info && <span className={s.infoMsg}>{translate(info)}</span>}
      </LegacyDiv>
    );
  },
);

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
  label: t.string,
  info: t.string,
  error: t.string,
  placeholder: t.string,
  required: t.oneOfType([t.bool, t.string]),
  resize: t.bool,
  rows: t.number,
  disabled: t.bool,
  readOnly: t.bool,
};

export const FormTextArea = withForm(TextArea);
