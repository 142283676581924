import React, { forwardRef } from 'react';

import t from 'prop-types';
import ReactNumericInput, {
  NumericInputProps as ReactNumericInputProps,
} from 'react-numeric-input';

import useTranslate from '@shared/components/hooks/useTranslate';
import LegacyDiv from '@shared/components/LegacyDiv';

import { withFormControl } from '../Form';
import Label from '../Label';

import s from './index.module.scss';

interface NumericInputProps extends ReactNumericInputProps {
  label?: string;
  info?: string;
  error?: string;
  className?: never;
}

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
  (
    {
      label = '',
      info,
      error,
      placeholder,
      required,
      id = crypto.randomUUID(),
      className: _className,
      onChange,
      name: _name, // We cant use name with ReactNumericInput component
      ...props
    },
    ref,
  ) => {
    const translate = useTranslate();

    return (
      <LegacyDiv className={s.numericInputWrap}>
        {label && <Label htmlFor={id} label={label} required={required} />}

        <ReactNumericInput
          id={id}
          {...props}
          ref={(el: ReactNumericInput & { refsInput: HTMLInputElement }) => {
            if (el && typeof ref === 'function') {
              ref(el.refsInput);
            }
          }}
          className={s.numericInput}
          placeholder={placeholder ? translate(placeholder) : ''}
          onChange={onChange}
        />

        {error && <span className={s.errorMsg}>{translate(error)}</span>}

        {info && <span className={s.infoMsg}>{translate(info)}</span>}
      </LegacyDiv>
    );
  },
);

NumericInput.displayName = 'NumericInput';
NumericInput.propTypes = {
  label: t.string,
  info: t.string,
  error: t.string,
  placeholder: t.string,
  precision: t.number,
  step: t.number,
  required: t.bool,
};

export const FormNumericInput = withFormControl(NumericInput);

FormNumericInput.propTypes = {
  name: t.string.isRequired,
};
