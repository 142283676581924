import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import t from 'prop-types';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import Translate from '../../Translate';
import { withForm } from '../Form';

import style from './index.module.scss';

/* If checkbox/radio is disabled formData for that checkbox/radio value will be undefined(applies when using Checkbox/Radio component inside Form component)  */
/* https://github.com/react-hook-form/react-hook-form/discussions/4413 */

// HTML spec does not support readOnly attributes on checkbox/radio
// There is an improvisation with the tabIndex attribute so checkbox/radio is not focusable

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: never;
  disabled?: boolean;
  info?: string;
  label?: string;
  labelWrap?: boolean;
  readOnly?: boolean;
}

export const Checkbox = forwardRef(
  (
    {
      labelWrap = true,
      label = '',
      info,
      readOnly,
      title,
      ...props
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const isDisabled = props.disabled || readOnly;

    return (
      <LegacyDiv
        className={classNames(
          style.checkboxWrap,
          isDisabled && style.checkboxDisabled,
        )}>
        <label className={style.label}>
          <LegacyInput
            {...props}
            tabIndex={isDisabled ? -1 : props.tabIndex}
            ref={ref}
            className={style.checkbox}
            type="checkbox"
          />
          <span
            className={classNames(!labelWrap && 'overflow-ellipsis')}
            title={title}>
            <Translate>{label}</Translate>
          </span>
        </label>

        {info && (
          <span className={style.infoMsg}>
            <Translate>{info}</Translate>
          </span>
        )}
      </LegacyDiv>
    );
  },
);

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
  label: t.string,
  info: t.string,
  disabled: t.bool,
  readOnly: t.bool,
};

export const FormCheckbox = withForm(Checkbox);

FormCheckbox.propTypes = {
  name: t.string.isRequired,
};
