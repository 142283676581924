import fastDeepEqual from 'fast-deep-equal';
import R from 'ramda';

import { SortOrder } from '../constants';

const { curry, find, propEq, is } = R;

export const flatten = (array, key) =>
  array.reduce((acc, obj) => {
    if (is(Object, obj)) {
      acc.push(obj);

      if (obj[key]) {
        return acc.concat(flatten(obj[key], key));
      }
    }

    return acc;
  }, []);

export const findWhere = curry(
  (
    prop, //eslint-disable-line
    value,
    array = [],
  ) => find(propEq(prop, value), array),
);

export const findWhereId = curry((value, array) =>
  findWhere('id', +value, array),
);

export const denormById = R.curry((mappedData, obj) => {
  if (R.isNil(obj) || R.isEmpty(obj)) {
    return;
  }

  const ids = R.keys(mappedData);

  const denormalizeFunction = (value, key) => {
    const keyName = key.slice(0, -2);
    const denormValue = R.path([key, value], mappedData);

    if (!denormValue || key.slice(-2) !== 'Id') {
      return value;
    }

    return {
      [keyName]: denormValue,
    };
  };

  const denormalizedObject = R.compose(
    R.mergeAll,
    R.values,
  )(R.mapObjIndexed(denormalizeFunction, R.pick(ids, obj)));

  return R.merge(obj, denormalizedObject);
});

export const indexArrayByPropName = (array = [], propName = 'id') =>
  array.reduce((result, item) => {
    const key = item[propName];
    result[key] = item;
    return result;
  }, {});

const sortPropValue = (sortProp, value) => {
  if (typeof sortProp === 'function') return sortProp(value);
  if (typeof value[sortProp] === 'string') return value[sortProp].toUpperCase();

  return value[sortProp];
};

export const sortArrayOfObjectsByProp = (
  array,
  sortProp,
  sortType = SortOrder.ASC,
) => {
  if (!array || !sortProp) {
    return [];
  }
  return [...array].sort((a, b) => {
    const itemA = sortPropValue(sortProp, a);
    const itemB = sortPropValue(sortProp, b);

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }

    return sortType === SortOrder.ASC ? comparison : comparison * -1;
  });
};

export const debounce = (func, time = 250) => {
  let interval;

  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      func(...args);
    }, time);
  };
};

export const round = (value, decimals = 2) => {
  if (typeof value !== 'number') {
    return value;
  }
  const number = 10 ** decimals;
  return Math.round(value * number) / number;
};

export const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const chunkArray = (array, size) => {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index));
    index += size;
  }

  return chunkedArray;
};

export const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1).toLowerCase();

export const isNil = (value) => value === null || value === undefined;

export const ensureValue = (value) => {
  if (isNil(value)) throw new Error('Value is undefined or null');
  return value;
};

export const someIsNil = (...values) =>
  values.some((value) => value === null || value === undefined);

const returnComparison = (sortProp, a, b) => {
  const itemA = sortPropValue(sortProp, Array.isArray(a) ? a[0] : a);
  const itemB = sortPropValue(sortProp, Array.isArray(b) ? b[0] : b);

  let comparison = 0;
  if (itemA > itemB) {
    comparison = 1;
  } else if (itemA < itemB) {
    comparison = -1;
  }
  return comparison;
};

export const sortArrayOfObjectsByMultipleProps = (
  array,
  sortProps,
  sortType = SortOrder.ASC,
) => {
  if (isEmpty(array) || !array) {
    return [];
  }
  if (!Array.isArray(sortProps) || !sortProps.length) {
    return array;
  }
  const numberOfProps = sortProps.length;

  return [...array].sort((a, b) => {
    let i = 0;
    let comparison = returnComparison(sortProps[i], a, b);
    while (comparison === 0) {
      i += 1;
      if (i < numberOfProps) {
        comparison = returnComparison(sortProps[i], a, b);
      }
      if (i === numberOfProps) return;
    }

    return sortType === SortOrder.ASC ? comparison : comparison * -1;
  });
};

export const getUniqueEntriesFromList = (list, get) => {
  if (isNil(get)) return [...new Set(list)];

  const ids = new Set();
  const getId = typeof get === 'function' ? get : (item) => item[get];

  return list.filter((item) => {
    if (isNil(item)) return false;

    const id = getId(item);

    if (ids.has(id)) return false;

    ids.add(id);
    return true;
  });
};

export const mockApiCall = (responseData, time = 1000, isRejection) =>
  new Promise((resolve, reject) => {
    setTimeout(
      () =>
        isRejection
          ? // eslint-disable-next-line prefer-promise-reject-errors
            reject(responseData)
          : resolve({ body: responseData }),

      time,
    );
  });

export const escapeRegExp = (regExp) =>
  regExp?.toString().replace(/[-[\]{}()*+!<=:?./\\^$|,]/g, '\\$&') || '';

export const generateNumericOptions = (numberOfOptions, startsWith = 1) =>
  [...new Array(numberOfOptions)].map((_, i) => ({
    value: i + startsWith,
    label: `${i + startsWith}`,
  }));

export const getTextExcerpt = (text, maxLength) => {
  if (typeof maxLength !== 'number' || text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};

export const safeJsonParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export const isEqual = (a, b) => fastDeepEqual(a, b);

export const isNot = (x) => !x;

export const omit = (obj, keys) => {
  const result = { ...obj };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
};

export const asyncScrollIntoView = ({
  scrollingElement,
  element,
  scrollOptions = { behavior: 'smooth' },
  debounceTimer = 50,
}) =>
  new Promise((resolve) => {
    let timer;

    const handleScroll = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resolve();
        scrollingElement.removeEventListener('scroll', handleScroll);
      }, debounceTimer);
    };

    scrollingElement.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    element.scrollIntoView(scrollOptions);
  });

export const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

// This is basically Object.keys but with type inferring for the keys kept intact
export const getObjectKeysWithType = (items) => {
  const typedKeyArray = [];
  // our eslint dont allow modifying Object.prototype, for this specific util we can consider this safe
  // eslint-disable-next-line no-restricted-syntax
  for (const key in items) {
    if (Object.prototype.hasOwnProperty.call(items, key)) {
      typedKeyArray.push(key);
    }
  }
  return typedKeyArray;
};

export const isObject = (item) =>
  typeof item === 'object' && !Array.isArray(item) && item != null;

export const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));
