import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Router } from '@reach/router';

import { LOGIN_ERROR_MESSAGE } from '@shared/constants';
import { parseQueryString, useNavigate } from '@shared/utils/route';

import { authenticated } from '../../api/authentication';
import { UserContext } from '../../components/Context';
import config from '../../config';
import { updateConfig } from '../../config/utils';
import { useApi } from '../hooks/api';
import Login from '../Login';
import {
  getContextAndTargetPath,
  handleRedirectOfTargetUri,
} from '../Login/utils';
import Fallback from './Fallback';
import InternetExplorerBlocker from './InternetExplorerBlocker';

const ManagerPortal = lazy(() =>
  import(
    /* webpackChunkName: "managerPortal" */ '../../../ManagerPortal/containers/ManagerPortal'
  ),
);

const StaffPortal = lazy(() =>
  import(/* webpackChunkName: "staffPortal" */ '../../../StaffPortal'),
);

const groupIdFromPath = (path) => (path || '').split('/')[1];

const Private = ({ location, '*': path }) => {
  const [managerPortalAuth, handleManagerPortalAuth] = useApi(authenticated);
  const [managerPortalUser, setManagerPortalUser] = useState({});
  const [hasStaffPortalAccess, setStaffPortalAccess] = useState(false);
  const navigate = useNavigate();
  const query = parseQueryString(location.search);

  // this is only for ending an SSO flow that originates in a popup, will close the popup after user is redirected back to Quinyx
  useEffect(() => {
    if (query.closePopup) {
      window.close();
    }
  }, [query]);

  const receiveManagerPortalUser = (response = {}) => {
    /* extract the values we want to update */
    const {
      config: { assets_baseurl, new_api, shard_id } = config,
      ...receivedUser
    } = response;
    if (config.shard_id !== shard_id) {
      updateConfig({ assets_baseurl, new_api, shard_id });
    }
    setManagerPortalUser(receivedUser);
  };

  const handleStaffPortalLogin = (error) => {
    const staffPortalOnly =
      error?.originalError?.body?.message ===
      LOGIN_ERROR_MESSAGE.NO_MANAGER_RIGHTS;

    if (!error || staffPortalOnly) {
      setStaffPortalAccess(true);
    }

    if (staffPortalOnly && !path.includes('staffPortal')) {
      navigate('/staffPortal');
    }
  };

  useEffect(() => {
    const contextAndPath = getContextAndTargetPath();
    handleManagerPortalAuth(contextAndPath.targetPath, contextAndPath.context)
      .then(({ data }) => {
        const finalUri = handleRedirectOfTargetUri(data.targetUri);
        receiveManagerPortalUser(data);
        handleStaffPortalLogin();
        // Two cases will trigger this flow: Navigation to / when already logged in, and switching account.
        // If returning to the page we should navigate to the targetPath or "/" if switching account we leave it to the frontend code for now.
        if (!contextAndPath.switchingAccount) navigate(finalUri);
      })
      .catch((error) => {
        receiveManagerPortalUser({});
        handleStaffPortalLogin(error);
      });
  }, []);

  const [staffPortalLanguage, setStaffPortalLanguage] = useState(
    () => query?.lng || navigator?.language?.toLocaleLowerCase() || 'en-gb',
  );

  const [staffPortalScheduleFilters, setStaffPortalScheduleFilters] =
    useState();

  const isInternetExplorer = !!document.documentMode;

  if (isInternetExplorer) {
    return <InternetExplorerBlocker />;
  }

  return (
    <Suspense fallback={<Fallback />}>
      <UserContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          user: managerPortalUser,
          receiveUser: receiveManagerPortalUser,
        }}>
        <Router className="height-100">
          {managerPortalUser.id && !path.includes('staffPortal') && (
            <ManagerPortal
              path="/*"
              user={managerPortalUser}
              groupIdFromPath={groupIdFromPath(path)}
              {...{ location }}
            />
          )}
          {hasStaffPortalAccess && (
            <StaffPortal
              path="/staffPortal/*"
              location={location}
              language={staffPortalLanguage}
              setLanguage={setStaffPortalLanguage}
              scheduleFilters={staffPortalScheduleFilters}
              setScheduleFilters={setStaffPortalScheduleFilters}
            />
          )}

          <Login
            default
            onManagerPortalLogin={receiveManagerPortalUser}
            onStaffPortalLogin={handleStaffPortalLogin}
            managerPortalAuth={managerPortalAuth}
            managerPortalUser={managerPortalUser}
          />
        </Router>
      </UserContext.Provider>
    </Suspense>
  );
};

export default Private;
